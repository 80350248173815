@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Poppins:wght@600;700&family=Roboto&display=swap');

@media only screen and (max-width:600px){
.namelogo{
    display: none;
}
}

@media only screen and (max-width:600px){
    .namelogo{
        position: absolute;
        font-family: Agustina;
        font-size: 30px;
        
    }
    .navlinkmenu1{
        text-decoration: none;
        color: aliceblue;
    }
}