
@media only screen and (max-width:599px){
    .projectpg{
        height: auto;
        width: 100%;
        background-color: #1A1A1A;
    }
    .projectmainpage{
        height: auto;
        width: 100%;
        margin-top: 10vh;
        display: grid;
        grid-gap: 5vh;
        background-color: #1A1A1A;
        /* background-color: aqua; */
    }
    .navbarlargeprojects{
        display: none;
    }
    .navbarsmallprojects{
        position: absolute;
        top: 0px;
    }
    .projecttitle{
        width: 80vw;
        margin-left: 10vw;
        align-items: center;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 500;
        color: aliceblue;
    }
    .projectscards1{
        display: grid;
        grid-gap: 4vh;
        width: 80vw;
        margin-left: 10vw;
    }
    .crd1{
        min-height: 70vh;
        width: 80vw;
        display: grid;
        margin-right: 5vw;
        background-color: gray;
    }
    .crd1:hover{
        background-color: #2b1331;
        background-image: linear-gradient(315deg, #2b1331 0%, #b9abcf 74%);
        box-shadow: 4px 8px 9px 5px #808080;
    }
    .crd2{
        min-height: 70vh;
        width: 80vw;
        display: grid;
        grid-gap: 2vh;
        margin-right: 5vw;
        background-color: gray;
    }
    .crd2:hover{
        background-color: #2b1331;
        background-image: linear-gradient(315deg, #2b1331 0%, #b9abcf 74%);
        box-shadow: 4px 8px 9px 5px #808080;
    }
    .crd3{
        min-height: 70vh;
        width: 80vw;
        display: grid;
        grid-gap: 2vh;
        background-color: gray;
    }
    .crd3:hover{
        background-color: #2b1331;
        background-image: linear-gradient(315deg, #2b1331 0%, #b9abcf 74%);
        box-shadow: 4px 8px 9px 5px #808080;
    }
    .caseira{
        height: auto;
        width: auto;
        margin-top: 2vh;
        margin-left: 10%;
    }
    .caseiraimg{
        height: 10vh;
        width: 20vw;
        border-radius: 15px;
        object-fit: cover;
    }
    
    .tool{
        height: auto;
        width: auto;
        margin-left: 10%;
        color: #1A1A1A;
    }
    .nameproject1{
        height: auto;
        width: auto;
        margin-left: 10%;
        font-size: large;
        font-weight: bold;
        color: aliceblue;
    }
    .project1txt{
        width: 80%;
        margin-left: 10%;
        text-align: justify;
        color: aliceblue;
    }
    .icondiv{
        height: auto;
        width: auto;
        bottom: 0px;
        margin-left: 10%;
    }
    .icon{
        height: auto;
        width: 8vw;
    }
    .projectscards2{
        display: grid;
        grid-gap: 4vh;
        width: 80vw;
        margin-left: 10vw;
        margin-bottom: 5vh;
    }
    .crd1b{
        min-height: 70vh;
        width: 80vw;
        display: grid;
        grid-gap: 2vh;
        margin-right: 5vw;
        background-color: gray;
    }
    .crd1b:hover{
        background-color: #2b1331;
        background-image: linear-gradient(315deg, #2b1331 0%, #b9abcf 74%);
        box-shadow: 4px 8px 9px 5px #808080;
    }
    .crd2b{
        min-height: 70vh;
        width: 80vw;
        display: grid;
        grid-gap: 2vh;
        margin-right: 5vw;
        background-color: gray;
    }
    .crd2b:hover{
        background-color: #2b1331;
        background-image: linear-gradient(315deg, #2b1331 0%, #b9abcf 74%);
        box-shadow: 4px 8px 9px 5px #808080;
    }
    .crd3b{
        min-height: 70vh;
        width: 80vw;
        display: grid;
        grid-gap: 2vh;
        background-color: gray;
    }
    .crd3b:hover{
        background-color: #2b1331;
        background-image: linear-gradient(315deg, #2b1331 0%, #b9abcf 74%);
        box-shadow: 4px 8px 9px 5px #808080;
    }
}

@media (min-width:600px) and (max-width:968px){
    .projectpg{
        height: auto;
        width: 100%;
        background-color: #1A1A1A;
        /* background-color: antiquewhite; */
    }
    .projectmainpage{
        height: auto;
        width: 100%;
        margin-top: 10vh;
        display: grid;
        grid-gap: 5vh;
        background-color: #1A1A1A;
        /* background-color: aqua;
    } */
    }
    .navbarlargeprojects{
        display: none;
    }
    .navbarsmallprojects{
        position: absolute;
        top: 0px;
    }
    .projecttitle{
        width: 80vw;
        margin-left: 10vw;
        align-items: center;
        text-align: center;
        font-size: 2rem;
        font-weight: bold;
        color: aliceblue;
    }
    .projectscards1{
        display: flex;
        width: 80vw;
        margin-left: 10vw;
    }
    .crd1{
        min-height: 70vh;
        width: 24vw;
        display: grid;
        margin-right: 5vw;
        background-color: gray;
    }
    .crd1:hover{
        background-color: #2b1331;
        background-image: linear-gradient(315deg, #2b1331 0%, #b9abcf 74%);
        box-shadow: 4px 8px 9px 5px #808080;
    }
    .crd2{
        min-height: 70vh;
        width: 24vw;
        display: grid;
        grid-gap: 2vh;
        margin-right: 5vw;
        background-color: gray;
    }
    .crd2:hover{
        background-color: #2b1331;
        background-image: linear-gradient(315deg, #2b1331 0%, #b9abcf 74%);
        box-shadow: 4px 8px 9px 5px #808080;
    }
    .crd3{
        min-height: 70vh;
        width: 24vw;
        display: grid;
        grid-gap: 2vh;
        background-color: gray;
    }
    .crd3:hover{
        background-color: #2b1331;
        background-image: linear-gradient(315deg, #2b1331 0%, #b9abcf 74%);
        box-shadow: 4px 8px 9px 5px #808080;
    }
    .caseira{
        height: auto;
        width: auto;
        margin-top: 2vh;
        margin-left: 10%;
    }
    .caseiraimg{
        height: 10vh;
        width: 10vw;
        border-radius: 15px;
        object-fit: cover;
    }
    
    .tool{
        height: auto;
        width: auto;
        margin-left: 10%;
        color: #1A1A1A;
    }
    .nameproject1{
        height: auto;
        width: auto;
        margin-left: 10%;
        font-size: large;
        font-weight: bold;
        color: aliceblue;
    }
    .project1txt{
        width: 80%;
        margin-left: 10%;
        text-align: justify;
        color: aliceblue;
    }
    .icondiv{
        height: 8vh;
        width: auto;
        bottom: 0px;
        margin-left: 10%;
    }
    .icon{
        height: 8vh;
        width: 4vw;
    }
    .projectscards2{
        display: flex;
        width: 80vw;
        margin-left: 10vw;
        margin-bottom: 5vh;
    }
    .crd1b{
        min-height: 70vh;
        width: 23.5vw;
        display: grid;
        grid-gap: 2vh;
        margin-right: 5vw;
        background-color: gray;
    }
    .crd1b:hover{
        background-color: #2b1331;
        background-image: linear-gradient(315deg, #2b1331 0%, #b9abcf 74%);
        box-shadow: 4px 8px 9px 5px #808080;
    }
    .crd2b{
        min-height: 70vh;
        width: 24vw;
        display: grid;
        grid-gap: 2vh;
        margin-right: 5vw;
        background-color: gray;
    }
    .crd2b:hover{
        background-color: #2b1331;
        background-image: linear-gradient(315deg, #2b1331 0%, #b9abcf 74%);
        box-shadow: 4px 8px 9px 5px #808080;
    }
    .crd3b{
        min-height: 70vh;
        width: 24vw;
        display: grid;
        grid-gap: 2vh;
        background-color: gray;
    }
    .crd3b:hover{
        background-color: #2b1331;
        background-image: linear-gradient(315deg, #2b1331 0%, #b9abcf 74%);
        box-shadow: 4px 8px 9px 5px #808080;
    }
}

@media only screen and (min-width:969px){
    .projectpg{
        height: auto;
        width: 100%;
        background-color: #1A1A1A;
        /* background-color: antiquewhite; */
    }
    .projectmainpage{
        height: auto;
        width: 100%;
        margin-top: 15vh;
        display: grid;
        grid-gap: 5vh;
        background-color: #1A1A1A;
        /* background-color: aqua; */
    
    }
    .navbarsmallprojects{
        display:none;
    }
    .navbarlargeprojects{
        position: absolute;
        top: 0px;
    }
    .projecttitle{
        width: 80vw;
        margin-left: 10vw;
        align-items: center;
        text-align: center;
        font-size: 2rem;
        font-weight: bold;
        color: aliceblue;
    }
    .projectscards1{
        display: flex;
        width: 80vw;
        margin-left: 10vw;
    }
    .crd1{
        min-height: 70vh;
        width: 24vw;
        display: grid;
        margin-right: 5vw;
        background-color: gray;
    }
    .crd1:hover{
        background-color: #2b1331;
        background-image: linear-gradient(315deg, #2b1331 0%, #b9abcf 74%);
        box-shadow: 4px 8px 9px 5px #808080;
    }
    .crd2{
        min-height: 70vh;
        width: 24vw;
        display: grid;
        grid-gap: 2vh;
        margin-right: 5vw;
        background-color: gray;
    }
    .crd2:hover{
        background-color: #2b1331;
        background-image: linear-gradient(315deg, #2b1331 0%, #b9abcf 74%);
        box-shadow: 4px 8px 9px 5px #808080;
    }
    .crd3{
        min-height: 70vh;
        width: 24vw;
        display: grid;
        grid-gap: 2vh;
        background-color: gray;
    }
    .crd3:hover{
        background-color: #2b1331;
        background-image: linear-gradient(315deg, #2b1331 0%, #b9abcf 74%);
        box-shadow: 4px 8px 9px 5px #808080;
    }
    .caseira{
        height: auto;
        width: auto;
        margin-top: 2vh;
        margin-left: 10%;
    }
    .caseiraimg{
        height: 10vh;
        width: 5vw;
        border-radius: 15px;
        object-fit: cover;
    }
    
    .tool{
        height: auto;
        width: auto;
        margin-left: 10%;
        color: #1A1A1A;
    }
    .nameproject1{
        height: auto;
        width: auto;
        margin-left: 10%;
        font-size: large;
        font-weight: bold;
        color: aliceblue;
    }
    .project1txt{
        width: 80%;
        margin-left: 10%;
        text-align: justify;
        color: aliceblue;
    }
    .icondiv{
        height: 8vh;
        width: auto;
        bottom: 0px;
        margin-left: 10%;
    }
    .icon{
        height: 8vh;
        width: 4vw;
    }
    .projectscards2{
        display: flex;
        width: 80vw;
        margin-left: 10vw;
        margin-bottom: 5vh;
    }
    .crd1b{
        min-height: 70vh;
        width: 23.5vw;
        display: grid;
        grid-gap: 2vh;
        margin-right: 5vw;
        background-color: gray;
    }
    .crd1b:hover{
        background-color: #2b1331;
        background-image: linear-gradient(315deg, #2b1331 0%, #b9abcf 74%);
        box-shadow: 4px 8px 9px 5px #808080;
    }
    .crd2b{
        min-height: 70vh;
        width: 24vw;
        display: grid;
        grid-gap: 2vh;
        margin-right: 5vw;
        background-color: gray;
    }
    .crd2b:hover{
        background-color: #2b1331;
        background-image: linear-gradient(315deg, #2b1331 0%, #b9abcf 74%);
        box-shadow: 4px 8px 9px 5px #808080;
    }
    .crd3b{
        min-height: 70vh;
        width: 24vw;
        display: grid;
        grid-gap: 2vh;
        background-color: gray;
    }
    .crd3b:hover{
        background-color: #2b1331;
        background-image: linear-gradient(315deg, #2b1331 0%, #b9abcf 74%);
        box-shadow: 4px 8px 9px 5px #808080;
    }
}




