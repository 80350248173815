@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Poppins:wght@600;700&family=Roboto&display=swap');

    
@media only screen and (min-width:969px){
    
    .navbarpg{
        width: 100%;
        /* background-image: url(peakpx3.jpg); */
        position: fixed;
        z-index: 999;
        height: 12vh;
        display: flex;
    }
    .navbarforall{
        width: 85vw;
        height: 5vh;
        color: aliceblue;
        position: fixed;
        top: 5vh;
        left: 10vw;
        display: flex;
        justify-content: space-between;
        font-family: Poppins;
        font-size: 1.1rem;
    }
    @font-face {
        font-family: Agustina;
        src: url(Agustina-Signature.otf)
    }
    .namelogo{
        font-family: Agustina;
        font-size: 30px;
        /* font-weight: bold; */
    }
    .navbarmenu{
        width: 40vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        cursor: pointer;
    }
    .navlinkmenu{
        text-decoration: none;
        color: aliceblue;
    }
    #icon{
        width: 25px;
        height: 25px;
    }
    
}
@media only screen and (max-width:968px){
.navbarpg{
    width: 100%;
    background-color: transparent;
    /* background-image: url(peakpx3.jpg); */
    position: fixed;
    z-index: 999;
    height: 12vh;
    display: none;
}
.navbarforall{
    width: 85vw;
    height: 5vh;
    color: aliceblue;
    position: fixed;
    top: 5vh;
    left: 10vw;
    display: flex;
    justify-content: space-between;
    font-family: Poppins;
    font-size: 1.1rem;
}
@font-face {
    font-family: Agustina;
    src: url(Agustina-Signature.otf)
}
.namelogo{
    font-family: Agustina;
    font-size: 30px;
    /* font-weight: bold; */
}
.navbarmenu{
    width: 40vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    cursor: pointer;
}
.navlinkmenu{
    text-decoration: none;
    color: aliceblue;
} 
}
.scroll {
    background-color: #202020;
    box-shadow: 0 0 25px 5px #000;
}